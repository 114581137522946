import ZidikaBusiness from "./ZidikaBusiness";
import React, { useState, useEffect } from "react";
import Axios from "../config/axios.config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import { Button, Input } from "@mui/material";
import DangerousIcon from "@mui/icons-material/Dangerous";
import WarningIcon from "@mui/icons-material/Warning";
import Swal from "sweetalert2";

function Businesses() {
  const [businesses, setBusinesses] = useState([]);
  const [notices, setNotices] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [business, setBusiness] = useState();

  useEffect(() => {
    Axios.get("/admin/panel/dashboard").then((response) => {
      setBusinesses(response.data.data.active);
      setNotices(response.data.data.notice);
    });
  }, []);

  useEffect(() => {
    Axios.get("/admin/panel/invoices/paid").then((response) => {
      setInvoices(response.data.data);
    });
  }, []);

  const [active, setActive] = useState("new");
  const [body, setBody] = useState("");
  const [subject, setSubject] = useState("");
  const [broadcasting, setBroadcasting] = useState(false);

  const broadcastMessage = () => {
    if (broadcasting) {
      toast.error("Another broadcast is currently ongoing");
      return;
    }
    if (subject === "" || body === "") {
      toast.error("Fill in all the fields");
      return;
    }
    setBroadcasting(true);
    Axios.post("/admin/panel/broadcast/message", {
      body,
      subject,
    }).then((response) => {
      console.log(response.data);
      setBroadcasting(false);
      if (response.data.success) {
        setBody("");
        setSubject("");
        toast.success("Broadcast Message Sent!");
      } else {
        toast.error("Failed to Broadcast Message");
      }
    });
  };

  const approveInv = (id) => {
    Axios.post("/admin/panel/invoices/approve", { id }).then((response) => {
      setInvoices(response.data.data);
    });
  };

  const declineInv = (id) => {
    Axios.post("/admin/panel/invoices/decline", { id }).then((response) => {
      setInvoices(response.data.data);
    });
  };

  const selectBusiness = (bizz) => {
    setCurrent(bizz._id);
    setBusiness(bizz);
  };

  const vacateSilent = async (id) => {
    Swal.fire({
      title:
        "Are you sure you want to vacate the store. All data related to the store will be deleted without notifying the owner?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
      customClass: {
        actions: "my-actions",
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.post("/admin/panel/vacate/silent", { id }).then((response) => {});
      } else if (result.isDenied) {
      }
    });
  };

  const vacate = (id) => {
    Swal.fire({
      title:
        "Are you sure you want to vacate the store. All data related to the store will be deleted?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
      customClass: {
        actions: "my-actions",
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.post("/admin/panel/vacate", { id }).then((response) => {});
      } else if (result.isDenied) {
      }
    });
  };

  const vacateCancel = (id) => {
    Swal.fire({
      title: "Are you sure you dont want to vacate the store?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
      customClass: {
        actions: "my-actions",
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.post("/admin/panel/vacate/cancel", { id }).then((response) => {});
      } else if (result.isDenied) {
      }
    });
  };

  const [current, setCurrent] = useState("");

  return (
    <div
      className="container"
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
      }}
    >
      <div
        className=""
        style={{ width: "100%", margin: "0 auto", textAlign: "center" }}
      >
        <button onClick={() => setActive("paid")}>
          Paid Invoices ({invoices.length})
        </button>
        <button onClick={() => setActive("invoices")}>
          Invocies ({notices.length})
        </button>
        <button onClick={() => setActive("active")}>
          Active (
          {businesses &&
            businesses.filter(
              (bizz) =>
                bizz.email !== "vacant" &&
                bizz.phone !== "vacant" &&
                bizz.url !== "test.com"
            ).length}
          )
        </button>
        <button onClick={() => setActive("undeployed")}>
          Undeployed (
          {businesses &&
            businesses.filter(
              (bizz) =>
                bizz.email === "vacant" &&
                bizz.phone === "vacant" &&
                bizz.url === "test.com"
            ).length}
          )
        </button>
        <button onClick={() => setActive("deployed")}>
          Deployed (
          {businesses &&
            businesses.filter(
              (bizz) =>
                bizz.email === "vacant" &&
                bizz.phone === "vacant" &&
                bizz.url !== "test.com"
            ).length}
          )
        </button>
        <button onClick={() => setActive("new")}>
          New (
          {businesses &&
            businesses.filter(
              (bizz) => bizz.status && bizz.status.subscription === "new"
            ).length}
          )
        </button>
        <button onClick={() => setActive("broadcast")}>Broadcast</button>
      </div>
      {active === "paid" && (
        <div
          style={{
            width: "100%",
            minWidth: "300px",
            backgroundColor: "#eeeeee",
            borderRadius: "7px",
            boxShadow: "0 0 1px #aaaaaa",
            padding: "10px",
            margin: "10px 0",
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <>
            <h2 className="text-center mt">Invoices Paid</h2>
            {invoices.map((inv, key) => (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                  backgroundColor: "#ddd",
                  borderRadius: "5px",
                  padding: "7px",
                }}
                key={key}
              >
                <Input
                  value={"Date Paid: " + inv.datePaid}
                  label="Date Paid"
                ></Input>
                <Input value={"Amount: " + inv.amount} label="Amount"></Input>
                <Input
                  value={inv.modeOfPayment}
                  label="Mode of Payment"
                ></Input>
                <Button color="success" onClick={() => approveInv(inv._id)}>
                  Approve
                </Button>
                <Button color="error" onClick={() => declineInv(inv._id)}>
                  Decline
                </Button>
              </div>
            ))}
          </>
        </div>
      )}

      {active === "invoices" && (
        <div
          style={{
            width: "100%",
            minWidth: "300px",
            backgroundColor: "#eeeeee",
            borderRadius: "7px",
            boxShadow: "0 0 1px #aaaaaa",
            padding: "10px",
            margin: "10px 0",
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <h2 className="text-center">Invoices</h2>
          {notices.map((ntc, key) => (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                backgroundColor: "#ddd",
                borderRadius: "5px",
                padding: "7px",
                color: current === ntc._id ? "white" : "black",
                backgroundColor: current === ntc._id ? "#202020" : "#ddd",
              }}
              key={key}
            >
              <a href={"https://" + ntc.url}>{ntc.name}</a>
              <span>{ntc.phone}</span>
              <span>{ntc.email}</span>
              <span>{ntc.expireStatus}</span>
              {ntc.vacated && ntc.vacated.state ? (
                <>
                  <Button
                    variant="contained"
                    color="error"
                    style={{ margin: "0 5px" }}
                    onClick={() => vacateCancel(ntc._id)}
                  >
                    Cancel&nbsp;
                    <DangerousIcon />
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="error"
                    style={{ margin: "0 5px" }}
                    onClick={() => vacateSilent(ntc._id)}
                  >
                    Vacate&nbsp;
                    <DangerousIcon />
                  </Button>
                  <Button variant="contained" onClick={() => vacate(ntc._id)}>
                    Vacate <WarningIcon />{" "}
                  </Button>
                </>
              )}
            </div>
          ))}
        </div>
      )}

      {active === "active" && (
        <div
          style={{
            width: "100%",
            minWidth: "300px",
            backgroundColor: "#eeeeee",
            borderRadius: "7px",
            boxShadow: "0 0 1px #aaaaaa",
            padding: "10px",
            margin: "10px 0",
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <h2 className="text-center">Businesses</h2>
          <div className="businesses">
            {businesses &&
              businesses
                .filter(
                  (bizz) =>
                    bizz.email !== "vacant" &&
                    bizz.phone !== "vacant" &&
                    bizz.url !== "test.com"
                )
                .map((business, key) => {
                  let t2b = business;

                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        width: "100%",
                        padding: "5px 0",
                        margin: "4px 0",
                        color: current === business._id ? "white" : "black",
                        backgroundColor:
                          current === business._id ? "#202020" : "#ddd",
                      }}
                      key={key}
                    >
                      <label
                        className="me-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => selectBusiness(business)}
                      >
                        {t2b.name}
                      </label>
                      <a href={"https://" + t2b.url}>{t2b.url} </a>
                    </div>
                  );
                })}
          </div>
        </div>
      )}

      {active === "undeployed" && (
        <div
          style={{
            width: "100%",
            minWidth: "300px",
            backgroundColor: "#eeeeee",
            borderRadius: "7px",
            boxShadow: "0 0 1px #aaaaaa",
            padding: "10px",
            margin: "10px 0",
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <h2 className="text-center">Vacant Undeployed</h2>
          <div className="businesses">
            {businesses &&
              businesses
                .filter(
                  (bizz) =>
                    bizz.email === "vacant" &&
                    bizz.phone === "vacant" &&
                    bizz.url === "test.com"
                )
                .map((business, key) => {
                  let t2b = business;

                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        width: "100%",
                        padding: "1px 0",
                        margin: "1px 0",
                        color: current === business._id ? "white" : "black",
                        backgroundColor:
                          current === business._id ? "#202020" : "#ddd",
                      }}
                      key={key}
                    >
                      <label
                        className="me-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => selectBusiness(business)}
                      >
                        {t2b.name}
                      </label>
                      <a href={"https:/" + t2b.url}>{t2b.url}</a>
                    </div>
                  );
                })}
          </div>
        </div>
      )}
      {active === "deployed" && (
        <div
          style={{
            width: "100%",
            minWidth: "300px",
            backgroundColor: "#eeeeee",
            borderRadius: "7px",
            boxShadow: "0 0 1px #aaaaaa",
            padding: "10px",
            margin: "10px 0",
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <h2 className="text-center">Vacant Deployed</h2>
          <div className="businesses">
            {businesses &&
              businesses
                .filter(
                  (bizz) =>
                    bizz.email === "vacant" &&
                    bizz.phone === "vacant" &&
                    bizz.url !== "test.com"
                )
                .map((business, key) => {
                  let t2b = business;

                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        width: "100%",
                        padding: "1px 0",
                        margin: "1px 0",
                        color: current === business._id ? "white" : "black",
                        backgroundColor:
                          current === business._id ? "#202020" : "#ddd",
                      }}
                      key={key}
                    >
                      <label
                        className="me-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => selectBusiness(business)}
                      >
                        {t2b.name}
                      </label>
                      <a href={"https:/" + t2b.url}>{t2b.url}</a>
                    </div>
                  );
                })}
          </div>
        </div>
      )}

      {active === "new" && (
        <div
          style={{
            width: "100%",
            minWidth: "300px",
            backgroundColor: "#eeeeee",
            borderRadius: "7px",
            boxShadow: "0 0 1px #aaaaaa",
            padding: "10px",
            margin: "10px 0",
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <h2 className="text-center">New Signups</h2>
          <div className="businesses">
            {businesses &&
              businesses
                .filter(
                  (bizz) => bizz.status && bizz.status.subscription === "new"
                )
                .map((business, key) => {
                  let t2b = business;
                  let abt = business.about;

                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        width: "100%",
                        padding: "1px 0",
                        margin: "1px 0",
                        color: current === business._id ? "white" : "black",
                        backgroundColor:
                          current === business._id ? "#202020" : "#ddd",
                      }}
                      key={key}
                    >
                      <label
                        className="me-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => selectBusiness(business)}
                      >
                        {t2b.name}
                      </label>
                      <a href={"https:/" + t2b.url}>{t2b.url}</a>
                    </div>
                  );
                })}
          </div>
        </div>
      )}

      {active === "broadcast" && (
        <div
          style={{
            width: "100%",
            minWidth: "300px",
            backgroundColor: "#eeeeee",
            borderRadius: "7px",
            boxShadow: "0 0 1px #aaaaaa",
            padding: "10px",
            margin: "10px 0",
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <h2 className="text-center">Broadcast Message</h2>
          <div className="businesses">
            <TextField
              label="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              style={{ width: "100%" }}
            />
            <TextField
              label="Body"
              value={body}
              onChange={(e) => setBody(e.target.value)}
              style={{ width: "100%", margin: "20px 0" }}
            />
            <button
              onClick={broadcastMessage}
              className="btn btn-primary"
              style={{
                marginLeft: "10px",
                backgroundColor: "green",
              }}
            >
              {broadcasting ? "Broadcasting..." : "Broadcast"}
            </button>
          </div>
        </div>
      )}

      {["new", "active", "undeployed", "deployed"].includes(active) && (
        <div
          style={{
            width: "97%",
            minWidth: "300px",
            backgroundColor: "#eeeeee",
            borderRadius: "7px",
            boxShadow: "0 0 1px #aaaaaa",
            padding: "10px",
            margin: "10px 0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {business ? (
            <>
              <h2 className="text-center">{business.name}</h2>
              <ZidikaBusiness business={business} />
            </>
          ) : (
            <p>No business selected</p>
          )}
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default Businesses;
