import "./App.css";
import React, { useEffect, useState } from "react";
import Users from "./components/Users";
import Businesses from "./components/Businesses";
import T2Businesses from "./components/T2Businesses";
import T3Businesses from "./components/T3Businesses";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import ZidikaBusiness from "./components/ZidikaBusinesses";
import Axios from "./config/axios.config";
import { Button, TextField } from "@mui/material";

function App() {
  const [loggedIn, setLoggedIn] = useState({ state: false, time: 0 });
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loggingIn, setLoggingIn] = useState(false);

  useEffect(() => {
    let loggedIn = JSON.parse(localStorage.getItem("loggedIn"));
    const now = new Date();
    if (loggedIn && loggedIn.state) {
      if (now.getTime() > loggedIn.time) {
        localStorage.removeItem("loggedIn");
      } else {
        setLoggedIn(loggedIn);
      }
    }
  });

  const login = async () => {
    setError("");
    if (!password) {
      setError("Please provide a password");
      return;
    }
    if (loggingIn) return;
    setLoggingIn(true);
    let res = await Axios.post("/admin/panel/login", {
      password,
    });
    if (res.data.success) {
      const now = new Date();

      setLoggedIn({
        state: true,
        time: now.getTime() + 30 * 60 * 1000,
      });
      window.localStorage.setItem("tkn", res.data.token);

      setPassword("");
    } else {
      setError("Invalid Credentials");
    }

    setLoggingIn(false);
  };

  const logout = () => {
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("tkn");
    setLoggedIn({ state: false, time: 0 });
  };

  useEffect(() => {
    window.localStorage.setItem("loggedIn", JSON.stringify(loggedIn));
  }, [loggedIn]);

  return (
    <div className="App">
      {!loggedIn.state ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="login">
            <h2>Login</h2>
            <TextField
              label="Password"
              value={password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              style={{ margin: "20px auto", width: "100%" }}
            />

            <Button
              onClick={login}
              style={{ backgroundColor: "dodgerblue", color: "white" }}
            >
              {loggingIn ? "Logging In..." : "Log In"}
            </Button>
            {error && (
              <p
                className=""
                style={{ color: "indianred", margin: "20px auto" }}
              >
                {error}
              </p>
            )}
          </div>
        </div>
      ) : (
        <Router>
          <nav className="navbar navbar-dark fixed-top navbar-expand-lg bg-dark">
            <Link to="/" className="navbar-brand mb-0 h1 p-2">
              Admin
            </Link>
            <button
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              className="navbar-toggler"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li>
                  <Link to="/" className="nav-link" onClick={logout}>
                    Log Out
                  </Link>
                </li>
                <li>
                  <Link to="/" className="nav-link">
                    Users
                  </Link>
                </li>
                <li>
                  <Link to="/tier1" className="nav-link">
                    Tier 1
                  </Link>
                </li>
                <li>
                  <Link to="/tier2" className="nav-link">
                    Tier 2
                  </Link>
                </li>
                <li>
                  <Link to="/tier3" className="nav-link">
                    Tier 3
                  </Link>
                </li>
                <li>
                  <Link to="/zidika" className="nav-link">
                    Zidika
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
          <Routes>
            <Route path="/" element={<Users />} />
            <Route path="/tier1" element={<Businesses />} />
            <Route path="/tier2" element={<T2Businesses />} />
            <Route path="/tier3" element={<T3Businesses />} />
            <Route path="/zidika" element={<ZidikaBusiness />} />
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
