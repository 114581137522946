import axios from "axios";

let production = process.env.REACT_APP_PRODUCTION;

const baseURL =
  production === "true"
    ? "https://server.bunikasolutions.com/"
    : "http://localhost:3001";

const backupUrl1 = "https://bunika.cyclic.app";
let instance = axios.create({
  baseURL,
});

instance.interceptors.request.use(
  (config) => {
    const mtoken = localStorage.getItem("tkn");

    if (mtoken) {
      config.headers["Authorization"] = `Bearer ${mtoken}`;
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async (config) => {
    return config;
  },

  async (error) => {
    let config = error.config;
    if (!config || config.baseURL === backupUrl1) {
      return Promise.reject(error);
    }
    if (config.response) {
      return config;
    }

    config.baseURL = backupUrl1;

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, config.retryDelay || 1000);
    });
    console.log("retry request");
    try {
      const retryResponse = await instance(config);
      return retryResponse;
    } catch (retryError) {
      return Promise.reject(retryError);
    }
  }
);

export default instance;
