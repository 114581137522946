import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Axios from "../config/axios.config";
import { TextField } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import JSZip from "jszip";
import { saveAs } from "file-saver";

function ZidikaBusiness({ business }) {
  let [update, setAction] = useState(false);
  let [mbizz, setBizz] = useState(business);
  const [updating, setUpdating] = useState(false);
  useEffect(() => {
    setBizz(business);
  }, [business]);

  const toggleUpdate = () => {
    setAction(!update);
  };

  const updateUrl = () => {
    Axios.post("/admin/panel/update/url", {
      _id: mbizz._id,
      url: mbizz.url,
    });
  };

  const sendWelcomeEmail = async () => {
    let res = await Axios.post("/business/zidika/welcome/email", {
      _id: mbizz._id,
    });
    if (res.data.success) {
      setBizz({ ...mbizz, welcomeEmailSent: true });
    }
  };

  const [selectedItem, setSelectedItem] = useState("");
  const selectItem = (id) => {
    setSelectedItem(id);
  };

  const downloadAllImages = async (urls, name) => {
    setDownloading(name);
    let promises = urls.map(async (url) => {
      url = url.replace(
        "https://storage.googleapis.com/test-bucket001/",
        "https://ik.imagekit.io/d4mmlivtj/goduka/tr:w-900,h-600/"
      );
      let r = await fetch(url);
      let blob = await r.blob();
      return blob;
    });
    let res = await Promise.all(promises);
    const zip = new JSZip();
    res.forEach((blob, index) => {
      zip.file(urls[index].split("/")[urls[index].split("/").length - 1], blob);
    });
    const file = await zip.generateAsync({ type: "blob" });
    saveAs(file, name + ".zip");
    setDownloading("");
  };
  const [downloading, setDownloading] = useState("");
  const [focused, setFocused] = useState("");
  const downloadImage = (url) => {
    setDownloading(url);
    let imageUrl = url;
    imageUrl = imageUrl.replace(
      "https://storage.googleapis.com/test-bucket001/",
      "https://ik.imagekit.io/d4mmlivtj/goduka/tr:w-900,h-600/"
    );

    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "image.jpg";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setDownloading("");
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
        setDownloading("");
      });
  };

  const saveChanges = async () => {
    if (
      !mbizz.name ||
      !mbizz.phone ||
      !mbizz.email ||
      !mbizz.location ||
      !mbizz.about
    ) {
      alert("Make sure all fields are filled");
      return;
    }
    setUpdating(true);
    let res = await Axios.post("/admin/panel/update/info", mbizz);
    if (res.data.success) {
      alert("Changes Saved");
    } else {
      alert("Failed to Save Changes");
    }
    setUpdating(false);
  };

  const [dataUrl, setDataUrl] = useState("");
  useEffect(() => {
    setDataUrl(
      "https://server.bunikasolutions.com/data/structured/xml/" + mbizz._id
    );
  }, [mbizz]);
  const [copying, setCopying] = useState(false);

  const copyLink = () => {
    setCopying(true);
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(dataUrl)
        .then(() => {
          alert("Structured Data URL Copied");
          setCopying(false);
        })
        .catch((err) => {
          alert("Failed to Copy Structured Data URL");
          setCopying(false);
        });
    } else {
      alert("Failed to Copy Structured Data URL");
      setCopying(false);
    }
  };
  return (
    <div className="business">
      <div
        className="text-dark p-3"
        style={{ display: "flex", flexWrap: "wrap" }}
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <TextField
            label="Name"
            value={mbizz.name}
            style={{ margin: "5px 10px" }}
            size="small"
            onChange={(e) => setBizz({ ...mbizz, name: e.target.value })}
          />

          <TextField
            label="Phone"
            value={mbizz.phone}
            style={{ margin: "5px 10px" }}
            size="small"
            onChange={(e) => setBizz({ ...mbizz, phone: e.target.value })}
          />

          <TextField
            label="Email"
            value={mbizz.email}
            style={{ margin: "5px 10px" }}
            size="small"
            onChange={(e) => setBizz({ ...mbizz, email: e.target.value })}
          />

          <TextField
            label="Location"
            value={mbizz.location}
            style={{ margin: "5px 10px" }}
            size="small"
            onChange={(e) => setBizz({ ...mbizz, location: e.target.value })}
          />
          <TextField
            label="Custom URL"
            value={mbizz.customUrl}
            style={{ margin: "5px 10px" }}
            size="small"
            onChange={(e) => setBizz({ ...mbizz, customUrl: e.target.value })}
          />

          <TextField
            label="Subscription"
            value={mbizz.subscription}
            style={{ margin: "5px 10px" }}
            size="small"
            onChange={(e) =>
              setBizz({ ...mbizz, subscription: e.target.value })
            }
          />

          <TextField
            label="About"
            value={mbizz.about}
            style={{ margin: "5px 10px" }}
            size="small"
            onChange={(e) => setBizz({ ...mbizz, about: e.target.value })}
          />

          <div
            style={{
              margin: "5px 10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              label="Url"
              value={mbizz.url}
              size="small"
              onChange={(e) => setBizz({ ...mbizz, url: e.target.value })}
            />

            <button
              onClick={updateUrl}
              className="btn btn-primary"
              style={{ margin: 0, marginLeft: "10px" }}
            >
              Update
            </button>
          </div>
          <div
            style={{
              margin: "5px 10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              label="Data Url"
              value={dataUrl}
              size="small"
              disabled={true}
            />

            <button
              onClick={copyLink}
              className="btn btn-primary"
              style={{ margin: 0, marginLeft: "10px" }}
            >
              {copying ? "Copying..." : "Copy Link"}
            </button>
          </div>
          <TextField
            label="GA4 Tag"
            style={{ margin: "5px 10px" }}
            value={mbizz.ga4Tag}
            size="small"
            onChange={(e) => setBizz({ ...mbizz, ga4Tag: e.target.value })}
          />
          <TextField
            label="Google Merchant Tag"
            style={{ margin: "5px 10px" }}
            value={mbizz.googleMerchantTag}
            size="small"
            onChange={(e) =>
              setBizz({ ...mbizz, googleMerchantTag: e.target.value })
            }
          />
          <TextField
            label="Store ID"
            style={{ margin: "5px 10px" }}
            value={mbizz._id}
            size="small"
            disabled={true}
          />

          <div
            style={{
              width: "100%",
              justifyContent: "start",
              marginBottom: "10px",
            }}
          >
            {!mbizz.welcomeEmailSent && (
              <button onClick={sendWelcomeEmail} className="btn btn-primary">
                Send Welcome Email{" "}
              </button>
            )}
            <button
              onClick={saveChanges}
              className="btn btn-primary"
              style={{
                marginLeft: "10px",
                backgroundColor: "green",
              }}
            >
              {updating ? "Saving..." : "Save Changes"}
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          <div
            style={{
              width: "48%",
              minWidth: "300px",
              maxHeight: "400px",
              overflow: "auto",
              padding: "7px",
              backgroundColor: "#eee",
            }}
          >
            <h3>Priority Items</h3>
            <h4>
              Slider Items (
              {
                mbizz.products.filter((prod) =>
                  prod.features.includes("Slider")
                ).length
              }
              ){" "}
              <span
                style={{ fontSize: "10pt", cursor: "pointer" }}
                onClick={() =>
                  downloadAllImages(
                    mbizz.products
                      .filter((prod) => prod.features.includes("Slider"))
                      .map((p) => p.img),
                    "slider"
                  )
                }
              >
                {downloading === "slider" ? (
                  <img src="./loading.gif" style={{ width: "18px" }} />
                ) : (
                  <DownloadIcon style={{ width: "15px" }} />
                )}
              </span>
            </h4>
            {mbizz.products
              .filter((prod) => prod.features.includes("Slider"))
              .map((prod, index) => (
                <div
                  key={index}
                  onClick={() => selectItem(prod._id)}
                  onMouseEnter={() => setFocused(prod.img)}
                  onMouseLeave={() => setFocused("")}
                >
                  {prod.name}{" "}
                  {prod.img === focused && (
                    <span>
                      <span
                        style={{ fontSize: "10pt", cursor: "pointer" }}
                        onClick={() => downloadImage(prod.img)}
                      >
                        {downloading === prod.img ? (
                          <img src="./loading.gif" style={{ width: "18px" }} />
                        ) : (
                          <DownloadIcon style={{ width: "15px" }} />
                        )}
                      </span>
                      <a
                        href={mbizz.url + "/filter/item/" + prod._id}
                        target="__blank"
                      >
                        <OpenInNewIcon
                          style={{ width: "15px", marginLeft: "10px" }}
                        />
                      </a>
                    </span>
                  )}
                </div>
              ))}
            <h4>
              Home Page Items (
              {
                mbizz.products.filter((prod) =>
                  prod.features.includes("Home Page")
                ).length
              }
              )
            </h4>
            {mbizz.products
              .filter((prod) => prod.features.includes("Home Page"))
              .map((prod, index) => (
                <div
                  key={index}
                  onClick={() => selectItem(prod._id)}
                  onMouseEnter={() => setFocused(prod.img)}
                  onMouseLeave={() => setFocused("")}
                >
                  {prod.name}{" "}
                  {prod.img === focused && (
                    <span>
                      <span
                        style={{ fontSize: "10pt", cursor: "pointer" }}
                        onClick={() => downloadImage(prod.img)}
                      >
                        {downloading === prod.img ? (
                          <img src="./loading.gif" style={{ width: "18px" }} />
                        ) : (
                          <DownloadIcon style={{ width: "15px" }} />
                        )}
                      </span>
                      <a
                        href={mbizz.url + "/filter/item/" + prod._id}
                        target="__blank"
                      >
                        <OpenInNewIcon
                          style={{ width: "15px", marginLeft: "10px" }}
                        />
                      </a>
                    </span>
                  )}
                </div>
              ))}
          </div>
          <div
            style={{
              width: "48%",
              minWidth: "300px",
              maxHeight: "400px",
              overflow: "auto",
              padding: "7px",
              backgroundColor: "#eee",
            }}
          >
            <h2>Other Items</h2>
            {mbizz.products
              .filter(
                (prod) =>
                  !(
                    prod.features.includes("Slider") ||
                    prod.features.includes("Home Page")
                  )
              )
              .map((prod, index) => (
                <div
                  key={index}
                  onClick={() => selectItem(prod._id)}
                  onMouseEnter={() => setFocused(prod.img)}
                  onMouseLeave={() => setFocused("")}
                >
                  {prod.name}{" "}
                  {prod.img === focused && (
                    <span>
                      <span
                        style={{ fontSize: "10pt", cursor: "pointer" }}
                        onClick={() => downloadImage(prod.img)}
                      >
                        {downloading === prod.img ? (
                          <img src="./loading.gif" style={{ width: "18px" }} />
                        ) : (
                          <DownloadIcon style={{ width: "15px" }} />
                        )}
                      </span>
                      <a
                        href={mbizz.url + "/filter/item/" + prod._id}
                        target="__blank"
                      >
                        <OpenInNewIcon
                          style={{ width: "15px", marginLeft: "10px" }}
                        />
                      </a>
                    </span>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default ZidikaBusiness;
