import Business from "./Business";
import React, { useState, useEffect } from 'react'
import Axios from './../config/axios.config'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Businesses() {

    const [businesses, setBusinesses] = useState([])

    useEffect(() => {
        Axios.get("/business/t1")
            .then((response) => {
                // console.log(response);
                setBusinesses(response.data);
            });
    }, []);



    const [name, setName] = useState("");
    const [type, setType] = useState("Product");
    const [bannerImage, setBanner] = useState(null);
    const [tagline, setTagline] = useState("");
    const [bandImage, setBand] = useState(null);
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [about, setAbout] = useState("");
    const [mon_to_fri, setMtF] = useState("")
    const [saturday, setSaturday] = useState("")
    const [sunday, setSunday] = useState("")
    const [holidays, setHolidays] = useState("")
    const [facebook, setFacebook] = useState("")
    const [twitter, setTwitter] = useState("")
    const [instagram, setInstagram] = useState("")
    const [linkedin, setLinkedIn] = useState("")

    const addBusiness = () => {

        if (name === "" || type === "" || tagline === "" || phone === "" || email === "" || address === ""
            || about === "" || mon_to_fri === "" || saturday === "" || sunday === "" || holidays === "") {
            toast.error("Fill in all the fields");
            return;
        }

        if (bannerImage == null || bandImage == null) {
            toast.error("Upload all necessary images")
            return;
        }

        if (bandImage.size > 2.5 * 1024 * 1024) {
            toast.error("Band Image is too large");
            return;
        }

        if (bannerImage.size > 2.5 * 1024 * 1024) {
            toast.error("Banner Image is too large");
            return;
        }

        const fd = new FormData();
        fd.append("name", name); fd.append("type", type);
        fd.append("bannerImage", bannerImage, "bannerImage");
        fd.append("tagline", tagline); fd.append("phone", phone);
        fd.append("bandImage", bandImage, "bandImage");
        fd.append("email", email); fd.append("address", address);
        fd.append("about", about);
        fd.append("mon_to_fri", mon_to_fri);
        fd.append("saturday", saturday);
        fd.append("sunday", sunday);
        fd.append("holidays", holidays);
        fd.append("fblink", facebook)
        fd.append("twitterlink", twitter)
        fd.append("iglink", instagram)
        fd.append("linkedInlink", linkedin)


        Axios.post("/business/t1", fd, {
            onUploadProgress: ProgressEvent => {
                console.log("Upload progress: " + Math.round(ProgressEvent.loaded / ProgressEvent.total * 100) + '%')
            }
        }
        ).then(response => {
            console.log(response.data);
            if (response.data.success) {
                setBusinesses([response.data.data, ...businesses]);
                setName(""); setType("Product"); setBanner(null);
                setTagline(""); setPhone(""); setBand(null);
                setEmail(""); setAddress(""); setAbout("");
                setMtF(""); setSaturday(""); setSunday("");
                setHolidays(""); setFacebook("");
                setTwitter(""); setLinkedIn(""); setInstagram("")
                toast.success("Tier 1 Business Added!");
            } else {

            }
        })


    };


    return <div className="container">
        <div className="form bg-light p-3">
            <h2>Add Business</h2>
            <div className="form-fields">
                <div>
                    <p>Business name:</p>
                    <input type="text" value={name} onChange={(event) => { setName(event.target.value) }} />
                </div>
                <div>
                    <p>Type:</p>
                    <select value={type} onChange={(event) => { setType(event.target.value) }}>
                        <option>Products</option>
                        <option>Services</option>
                    </select>
                </div>
                <div>
                    <p>Banner Image:</p>
                    <input type="file" onChange={(event) => { setBanner(event.target.files[0]) }} />
                </div>
                <div>
                    <p>Tag Line:</p>
                    <input type="text" value={tagline} onChange={(event) => { setTagline(event.target.value) }} />
                </div>
                <div>
                    <p>Band Image:</p>
                    <input type="file" onChange={(event) => { setBand(event.target.files[0]) }} />
                </div>
                <div>
                    <p>Phone:</p>
                    <input type="text" value={phone} onChange={(event) => { setPhone(event.target.value) }} />
                </div>
                <div>
                    <p>Email:</p>
                    <input type="text" value={email} onChange={(event) => { setEmail(event.target.value) }} />
                </div>
                <div>
                    <p>Address:</p>
                    <input type="text" value={address} onChange={(event) => { setAddress(event.target.value) }} />
                </div>
                <div className="abt-text">
                    <p>About:</p>
                    <textarea type="text" value={about} onChange={(event) => { setAbout(event.target.value) }}></textarea>
                </div>
                <div>
                    <p>Monday to Friday:</p>
                    <input type="text" value={mon_to_fri} onChange={(event) => { setMtF(event.target.value) }} />
                </div>
                <div>
                    <p>Saturday:</p>
                    <input type="text" value={saturday} onChange={(event) => { setSaturday(event.target.value) }} />
                </div>
                <div>
                    <p>Sunday:</p>
                    <input type="text" value={sunday} onChange={(event) => { setSunday(event.target.value) }} />
                </div>
                <div>
                    <p>Holidays:</p>
                    <input type="text" value={holidays} onChange={(event) => { setHolidays(event.target.value) }} />
                </div>
                <div>
                    <p>Facebook:</p>
                    <input type="text" value={facebook} onChange={(event) => { setFacebook(event.target.value) }} />
                </div>
                <div>
                    <p>Twitter:</p>
                    <input type="text" value={twitter} onChange={(event) => { setTwitter(event.target.value) }} />
                </div>
                <div>
                    <p>Instagram:</p>
                    <input type="text" value={instagram} onChange={(event) => { setInstagram(event.target.value) }} />
                </div>
                <div>
                    <p>LinkedIn:</p>
                    <input type="text" value={linkedin} onChange={(event) => { setLinkedIn(event.target.value) }} />
                </div>

            </div>
            <div className='text-center'>
                <button onClick={addBusiness} className="btn btn-primary">Add</button>
            </div>
        </div>
        <h2 className="text-center mt-5">Businesses</h2>
        <div className="businesses">
            {businesses.map((business, key) => <Business business={business} key={business._id} />)}
        </div>
        <ToastContainer />
    </div>;
}

export default Businesses;