import React, { useState } from 'react'
import Axios from './../config/axios.config'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function T2Business({ business, about }) {

    let [update, setAction] = useState(false);
    let [mbizz, setBizz] = useState(business);
    let [abt, setAbout] = useState(about);
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [homeImage, setHomeImage] = useState(null);
    const [summary, setSummary] = useState("");
    const [aboutImage, setAboutImage] = useState(null);
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [aboutTitle, setAboutTitle] = useState("");
    const [mon_to_fri, setMtF] = useState("")
    const [saturday, setSaturday] = useState("")
    const [sunday, setSunday] = useState("")
    const [holidays, setHolidays] = useState("")
    const [aboutSummary, setAboutSummary] = useState("")
    const [aboutHHelp, setAboutHHelp] = useState("")
    const [aboutQualifications, setAboutQualifications] = useState("")
    let typeT = business.type === "Blog" ? business.blogTitle : business.galleryTitle;
    const [typeTitle, setTypeTitle] = useState("");
    const [password, setPassword] = useState("")
    const [facebook, setFacebook] = useState("")
    const [twitter, setTwitter] = useState("")
    const [instagram, setInstagram] = useState("")
    const [linkedin, setLinkedIn] = useState("")


    const updateBusiness = () => {
        Axios.post("/business/t2/update/" + business._id, {
            name: name.length > 0 ? name : business.name,
            type: type.length > 0 ? type : business.type,
            phone: phone.length > 0 ? phone : business.phone,
            email: email.length > 0 ? email : business.email,
            summary: summary.length > 0 ? summary : business.summary,
            address: address.length > 0 ? address : business.address,
            aboutTitle: aboutTitle.length > 0 ? aboutTitle : business.about.title,
            mon_to_fri: mon_to_fri.length > 0 ? mon_to_fri : business.mon_to_fri,
            saturday: saturday.length > 0 ? saturday : business.saturday,
            sunday: sunday.length > 0 ? sunday : business.sunday,
            holidays: holidays.length > 0 ? holidays : business.holidays,
            aboutHHelp: aboutHHelp.length > 0 ? aboutHHelp : business.about.howHelp,
            aboutSummary: aboutSummary.length > 0 ? aboutSummary : business.about.summary,
            aboutQualifications: aboutQualifications.length > 0 ? aboutQualifications : business.about.qualifications,
            typeTitle: typeTitle.length > 0 ? typeTitle : typeT,
            password: password.length > 0 ? password : business.password,
            fblink: facebook.length > 0 ? facebook : business.fblink,
            twitterlink: twitter.length > 0 ? twitter : business.twitterlink,
            iglink: instagram.length > 0 ? instagram : business.iglink,
            linkedInlink: linkedin.length > 0 ? linkedin : business.linkedInlink
        }).then(response => {
            console.log(response.data);
            if (response.data.success) {
                setBizz(response.data.data);
                setAbout(response.data.data.about);
                setAction(false);
            } else {

            }
        })
    };

    const updateHomeImg = () => {
        const fd = new FormData()
        fd.append("homeImage", homeImage);

        if (homeImage.size > 2.5 * 1024 * 1024) {
            toast.error("Home Image is too large");
            return;
        }

        Axios.post("/business/t2/update/homeimage/" + business._id, fd)
            .then(response => {
                console.log(response.data);
                if (response.data.success) {
                    setBizz(response.data.data);
                    setAction(false);
                } else {

                }
            })
    };

    const updateAbout = () => {
        const fd = new FormData()

        if (aboutImage.size > 2.5 * 1024 * 1024) {
            toast.error("About Image is too large");
            return;
        }

        fd.append("aboutImage", aboutImage);
        Axios.post("/business/t2/update/aboutimage/" + business._id, fd)
            .then(response => {
                console.log(response.data);
                if (response.data.success) {
                    setBizz(response.data.data);
                    setAction(false);
                } else {

                }
            })
    };

    const toggleUpdate = () => {
        setAction(!update);
    }

    const deleteValue = (key) => {

        Axios.delete("/business/t2/value/" + business._id, { data: { key: key } })
            .then(response => {
                console.log(response.data);
                if (response.data.success) {
                    setBizz(response.data.data);
                    setAction(false);
                } else {

                }
            })
    }

    const deleteTestimony = (key) => {
        Axios.delete("/business/t2/testimony/" + business._id, { data: { key: key } })
            .then(response => {
                console.log(response.data);
                if (response.data.success) {
                    setBizz(response.data.data);
                    setAction(false);
                } else {

                }
            })
    }


    const [vtitle, setValueTitle] = useState("")
    const [vcontent, setValueContent] = useState("")

    const addValue = () => {
        Axios.post("/business/t2/value/" + business._id, {
            title: vtitle,
            content: vcontent
        })
            .then(response => {
                console.log(response.data);
                if (response.data.success) {
                    setBizz(response.data.data);
                    setAction(false);
                } else {

                }
            })
    }

    const [testyName, setTestyName] = useState("")
    const [testyComment, setTestyComment] = useState("")

    const addTestimony = () => {
        Axios.post("/business/t2/testimony/" + business._id, {
            name: testyName,
            comment: testyComment
        })
            .then(response => {
                console.log(response.data);
                if (response.data.success) {
                    setBizz(response.data.data);
                    setAction(false);
                } else {

                }
            })
    }

    const prevUrl = "https://bunika.netlify.app/preview/" + business._id;

    return <div className="business">

        {
            update ?
                <div>
                    <div className="business-info text-dark p-3 tier-2">
                        <fieldset>
                            <legend>General Information</legend>
                            <div>
                                <p>Business name:</p>
                                <input type="text" defaultValue={mbizz.name} placeholder={mbizz.name} onChange={(event) => { setName(event.target.value) }} />
                            </div>
                            <div>
                                <p>Type:</p>
                                <select defaultValue={mbizz.type} onChange={(event) => { setType(event.target.value) }} >
                                    <option>Blog</option>
                                    <option>Gallery</option>
                                </select>
                            </div>
                            <div>
                                <p>Type Title:</p>
                                <input type="text" defaultValue={mbizz.type === "Blog" ? mbizz.blogTitle : mbizz.galleryTitle} onChange={(event) => { setTypeTitle(event.target.value) }} />
                            </div>
                            <div>
                                <p>password:</p>
                                <input type="text" defaultValue={mbizz.password} onChange={(event) => { setPassword(event.target.value) }} />
                            </div>
                            <div className='t-area'>
                                <p>Summary:</p>
                                <textarea onChange={(event) => { setSummary(event.target.value) }}>{mbizz.summary}</textarea>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>Contacts</legend>
                            <div>
                                <p>Phone:</p>
                                <input type="text" defaultValue={mbizz.phone} onChange={(event) => { setPhone(event.target.value) }} />
                            </div>
                            <div>
                                <p>Email:</p>
                                <input type="text" defaultValue={mbizz.email} onChange={(event) => { setEmail(event.target.value) }} />
                            </div>
                            <div>
                                <p>Address:</p>
                                <input type="text" defaultValue={mbizz.address} onChange={(event) => { setAddress(event.target.value) }} />
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>Availability</legend>
                            <div>
                                <p>Monday to Friday:</p>
                                <input type="text" defaultValue={mbizz.mon_to_fri} onChange={(event) => { setMtF(event.target.value) }} />
                            </div>
                            <div>
                                <p>Saturday:</p>
                                <input type="text" defaultValue={mbizz.saturday} onChange={(event) => { setSaturday(event.target.value) }} />
                            </div>
                            <div>
                                <p>Sunday:</p>
                                <input type="text" defaultValue={mbizz.sunday} onChange={(event) => { setSunday(event.target.value) }} />
                            </div>
                            <div>
                                <p>Holidays:</p>
                                <input type="text" defaultValue={mbizz.holidays} onChange={(event) => { setHolidays(event.target.value) }} />
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>About Information</legend>
                            <div>
                                <p>About Title:</p>
                                <input type="text" defaultValue={about.title} onChange={(event) => { setAboutTitle(event.target.value) }} />
                            </div>
                            <div className='t-area'>
                                <p>About How Help:</p>
                                <textarea onChange={(event) => { setAboutHHelp(event.target.value) }}>{about.howHelp}</textarea>
                            </div>
                            <div className='t-area'>
                                <p>About Qualifications:</p>
                                <textarea onChange={(event) => { setAboutQualifications(event.target.value) }}>{about.qualifications}</textarea>
                            </div>
                            <div className='t-area'>
                                <p>About Summary:</p>
                                <textarea type="text" onChange={(event) => { setAboutSummary(event.target.value) }} defaultValue={about.summary}></textarea>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>Social Media</legend>
                            <div>
                                <p>Facebook:</p>
                                <input type="text" defaultValue={mbizz.fblink} onChange={(event) => { setFacebook(event.target.value) }} />
                            </div>
                            <div>
                                <p>Twitter:</p>
                                <input type="text" defaultValue={mbizz.twitterlink} onChange={(event) => { setTwitter(event.target.value) }} />
                            </div>
                            <div>
                                <p>Instagram:</p>
                                <input type="text" defaultValue={mbizz.iglink} onChange={(event) => { setInstagram(event.target.value) }} />
                            </div>
                            <div>
                                <p>LinkedIn:</p>
                                <input type="text" defaultValue={mbizz.linkedInlink} onChange={(event) => { setLinkedIn(event.target.value) }} />
                            </div>
                        </fieldset>
                    </div>
                    <button onClick={updateBusiness} className="btn btn-success">Save</button>
                    <div className="business-info text-dark p-3 tier-2">
                        <fieldset className='group'>
                            <legend>Values</legend>
                            {mbizz.values.map((value, key) =>
                                <div className="value" id={key}>
                                    <h5>{value.title}</h5>
                                    <p>{value.content}</p>
                                    <button className="btn btn-danger" onClick={() => deleteValue(Number(key))}>Delete</button>
                                </div>
                            )}
                            <div className="mt-2 t-area">
                                <h4>Add Value</h4>
                                <h5>Title</h5>
                                <input type="text" onChange={(e) => setValueTitle(e.target.value)} />
                                <h5>Content</h5>
                                <textarea onChange={(e) => setValueContent(e.target.value)}></textarea>
                                <br />
                                <button className="btn btn-primary" onClick={addValue}>Add Value</button>
                            </div>
                        </fieldset>
                        <fieldset className="group">
                            <legend>Testimonials:</legend>
                            <div>{mbizz.testimonials.map((testy, key) =>
                                <div className="testimonial">
                                    <h5>{testy.name}</h5>
                                    <p>{testy.comment}</p>
                                    <button className="btn btn-danger" onClick={() => deleteTestimony(key)}>Delete</button>
                                </div>
                            )}</div>
                            <div className="mt-2 t-area">
                                <h4>Add Testimony</h4>
                                <h5>Name</h5>
                                <input type="text" onChange={(e) => setTestyName(e.target.value)} />
                                <h5>Comment</h5>
                                <textarea onChange={(e) => setTestyComment(e.target.value)}></textarea>
                                <br />
                                <button className="btn btn-primary" onClick={addTestimony}>Add Testimony</button>
                            </div>
                        </fieldset>
                    </div>
                    <div className="images group m-3">
                        <div className="">
                            <h5>Home Image:</h5>
                            <input type="file" onChange={(event) => { setHomeImage(event.target.files[0]) }} />
                            <button onClick={updateHomeImg} className="btn btn-success">Save Image</button>
                        </div>
                        <div className="">
                            <h5>About Image:</h5>
                            <input type="file" onChange={(event) => { setAboutImage(event.target.files[0]) }} />
                            <button onClick={updateAbout} className="btn btn-success">Save Image</button>
                        </div>
                    </div>


                    <button onClick={toggleUpdate} className="btn btn-danger mb-2">Cancel</button>
                </div>
                :
                <div className="text-dark p-3">
                    <table>
                        <tbody>
                            <tr><td></td><td><a target="_blank" href={prevUrl} rel="noopener noreferrer"><button className="me-auto btn btn-primary">Preview</button></a></td></tr>
                            <tr><td>Name:</td><td>{mbizz.name}</td></tr>
                            <tr><td>Type:</td><td>{mbizz.type}</td></tr>
                            <tr><td>{mbizz.type + " title"}:</td><td>{mbizz.blogTitle || mbizz.galleryTitle}</td></tr>
                            <tr><td>Phone:</td><td>{mbizz.phone}</td></tr>
                            <tr><td>Email:</td><td>{mbizz.email}</td></tr>
                            <tr><td>Summary:</td><td>{abt.summary}</td></tr>
                            <tr><td>About Title:</td><td>{abt.title}</td></tr>
                            <tr><td>Address:</td><td>{mbizz.address}</td></tr>
                            <tr><td>Monday to Friday:</td><td>{mbizz.mon_to_fri}</td></tr>
                            <tr><td>Saturday:</td><td>{mbizz.saturday}</td></tr>
                            <tr><td>Sunday:</td><td>{mbizz.sunday}</td></tr>
                            <tr><td>Holidays:</td><td>{mbizz.holidays}</td></tr>
                            <tr><td>About How Help:</td><td>{abt.howHelp}</td></tr>
                            <tr><td>About Summary:</td><td>{abt.summary}</td></tr>
                            <tr><td>About Qualifications:</td><td>{abt.qualifications}</td></tr>
                            <tr><td>Facebook: </td><td>{mbizz.fblink}</td></tr>
                            <tr><td>Twitter: </td><td>{mbizz.twitterlink}</td></tr>
                            <tr><td>Instagram: </td><td>{mbizz.iglink}</td></tr>
                            <tr><td>LinkedIn: </td><td>{mbizz.linkedInlink}</td></tr>
                            <tr><td colSpan="2">
                                <h4>Values</h4>
                                <div className="table-multi">{mbizz.values.map((value, key) =>
                                    <div className="value m-3" key={key}>
                                        <h4>{value.title}</h4>
                                        <p>{value.content}</p>
                                    </div>
                                )}</div>
                            </td>
                            </tr>
                            <tr><td colSpan="2">
                                <h4>Testimonials</h4>
                                <div className="table-multi">{mbizz.testimonials.map((testy, key) =>
                                    <div className="testimonial m-3" key={key}>
                                        <h4>{testy.name}</h4>
                                        <p>{testy.comment}</p>
                                    </div>
                                )}</div>
                            </td>
                            </tr>
                            <tr><td><button onClick={toggleUpdate} className="btn btn-primary">Update</button></td></tr>
                        </tbody>
                    </table>
                </div>
        }
        <ToastContainer />
    </div>
}

export default T2Business;