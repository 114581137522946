import T2Business from "./T2Business";
import React, { useState, useEffect } from 'react'
import Axios from './../config/axios.config'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Businesses() {

    const [businesses, setBusinesses] = useState([])

    useEffect(() => {
        Axios.get("/business/t2")
            .then((response) => {
                console.log(response.data);
                setBusinesses(response.data);
            });
    }, []);



    const [name, setName] = useState("");
    const [type, setType] = useState("Blog");
    const [homeImage, setHomeImage] = useState(null);
    const [summary, setSummary] = useState("");
    const [aboutImage, setAboutImage] = useState(null);
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [aboutTitle, setAboutTitle] = useState("");
    const [mon_to_fri, setMtF] = useState("")
    const [saturday, setSaturday] = useState("")
    const [sunday, setSunday] = useState("")
    const [holidays, setHolidays] = useState("")
    const [aboutSummary, setAboutSummary] = useState("")
    const [aboutHHelp, setAboutHHelp] = useState("")
    const [aboutQualifications, setAboutQualifications] = useState("")
    const [typeTitle, setTypeTitle] = useState("")
    const [password, setPassword] = useState("")
    const [facebook, setFacebook] = useState("")
    const [twitter, setTwitter] = useState("")
    const [instagram, setInstagram] = useState("")
    const [linkedin, setLinkedIn] = useState("")

    const addBusiness = () => {
        const fd = new FormData();

        if (name === "" || type === "" || summary === "" || phone === "" || email === "" || address === ""
            || aboutTitle === "" || mon_to_fri === "" || saturday === "" || sunday === "" || holidays === ""
            || aboutSummary === "" || aboutHHelp === "" || aboutQualifications === "" || typeTitle === ""
            || password === "") {
            toast.error("Fill in all the fields");
            return;
        }

        if (homeImage == null || aboutImage == null) {
            toast.error("Upload all necessary images")
            return;
        }

        if (homeImage.size > 2.5 * 1024 * 1024) {
            toast.error("Home Image is too large");
            return;
        }

        if (aboutImage.size > 2.5 * 1024 * 1024) {
            toast.error("About Image is too large");
            return;
        }

        fd.append("name", name); fd.append("type", type);
        fd.append("homeImage", homeImage, "homeImage");
        fd.append("summary", summary); fd.append("phone", phone);
        fd.append("aboutImage", aboutImage, "aboutImage");
        fd.append("email", email); fd.append("address", address);
        fd.append("aboutTitle", aboutTitle);
        fd.append("mon_to_fri", mon_to_fri);
        fd.append("saturday", saturday);
        fd.append("sunday", sunday);
        fd.append("holidays", holidays);
        fd.append("aboutSummary", aboutSummary);
        fd.append("aboutHHelp", aboutHHelp);
        fd.append("aboutQualifications", aboutQualifications);
        fd.append("typeTitle", typeTitle);
        fd.append("password", password);
        fd.append("fblink", facebook)
        fd.append("twitterlink", twitter)
        fd.append("iglink", instagram)
        fd.append("linkedInlink", linkedin)

        Axios.post("/business/t2", fd, {
            onUploadProgress: ProgressEvent => {
                console.log("Upload progress: " + Math.round(ProgressEvent.loaded / ProgressEvent.total * 100) + '%')
            }
        }
        ).then(response => {
            console.log(response.data);
            if (response.data.success) {
                setBusinesses([response.data.data, ...businesses]);
                setName(""); setType("Blog"); setHomeImage(null)
                setSummary(""); setPhone("");
                setAboutImage(null); setEmail("");
                setAddress(""); setAboutTitle("")
                setMtF(""); setSaturday(""); setSunday("");
                setHolidays(""); setAboutSummary("");
                setAboutQualifications(""); setAboutHHelp("");
                setTypeTitle(""); setFacebook("");
                setTwitter(""); setLinkedIn(""); setInstagram("")
                toast.success("Tier-2 Business Added!")
            } else {

            }
        })


    };


    return <div className="container">
        <div className="tier-2 form bg-light p-3">
            <h2>Add Tier 2 Business</h2>
            <div className="form-fields">
                <fieldset>
                    <legend>General Information</legend>
                    <div>
                        <p>Business name:</p>
                        <input type="text" value={name} onChange={(event) => { setName(event.target.value) }} />
                    </div>
                    <div>
                        <p>Type:</p>
                        <select value={type} onChange={(event) => { setType(event.target.value) }}>
                            <option>Blog</option>
                            <option>Gallery</option>
                        </select>
                    </div>
                    <div>
                        <p>Type Title:</p>
                        <input type="text" value={typeTitle} onChange={(event) => { setTypeTitle(event.target.value) }} />
                    </div>
                    <div>
                        <p>Password:</p>
                        <input type="text" value={password} onChange={(event) => { setPassword(event.target.value) }} />
                    </div>
                    <div>
                        <p>Home Image:</p>
                        <input type="file" onChange={(event) => { setHomeImage(event.target.files[0]) }} />
                    </div>
                    <div className="t-area">
                        <p>Summary:</p>
                        <textarea value={summary} onChange={(event) => { setSummary(event.target.value) }}></textarea>
                    </div>
                </fieldset>
                <fieldset>
                    <legend>Contacts</legend>
                    <div>
                        <p>Phone:</p>
                        <input type="text" value={phone} onChange={(event) => { setPhone(event.target.value) }} />
                    </div>
                    <div>
                        <p>Email:</p>
                        <input type="text" value={email} onChange={(event) => { setEmail(event.target.value) }} />
                    </div>
                    <div>
                        <p>Address:</p>
                        <input type="text" value={address} onChange={(event) => { setAddress(event.target.value) }} />
                    </div>
                </fieldset>
                <fieldset>
                    <legend>Availability</legend>
                    <div>
                        <p>Monday to Friday:</p>
                        <input type="text" value={mon_to_fri} onChange={(event) => { setMtF(event.target.value) }} />
                    </div>
                    <div>
                        <p>Saturday:</p>
                        <input type="text" value={saturday} onChange={(event) => { setSaturday(event.target.value) }} />
                    </div>
                    <div>
                        <p>Sunday:</p>
                        <input type="text" value={sunday} onChange={(event) => { setSunday(event.target.value) }} />
                    </div>
                    <div>
                        <p>Holidays:</p>
                        <input type="text" value={holidays} onChange={(event) => { setHolidays(event.target.value) }} />
                    </div>
                </fieldset>
                <fieldset>
                    <legend>About Information</legend>
                    <div>
                        <p>About Image:</p>
                        <input type="file" onChange={(event) => { setAboutImage(event.target.files[0]) }} />
                    </div>
                    <div>
                        <p>About Title:</p>
                        <input type="text" value={aboutTitle} onChange={(event) => { setAboutTitle(event.target.value) }} />
                    </div>
                    <div className="t-area">
                        <p>About Summary:</p>
                        <textarea value={aboutSummary} onChange={(event) => { setAboutSummary(event.target.value) }}></textarea>
                    </div>
                    <div className="t-area">
                        <p>About How Help:</p>
                        <textarea value={aboutHHelp} onChange={(event) => { setAboutHHelp(event.target.value) }}></textarea>
                    </div>
                    <div className="t-area">
                        <p>About Qualifications:</p>
                        <textarea value={aboutQualifications} onChange={(event) => { setAboutQualifications(event.target.value) }}></textarea>
                    </div>
                </fieldset>
                <fieldset>
                    <legend>Social Media</legend>
                    <div>
                        <p>Facebook:</p>
                        <input type="text" value={facebook} onChange={(event) => { setFacebook(event.target.value) }} />
                    </div>
                    <div>
                        <p>Twitter:</p>
                        <input type="text" value={twitter} onChange={(event) => { setTwitter(event.target.value) }} />
                    </div>
                    <div>
                        <p>Instagram:</p>
                        <input type="text" value={instagram} onChange={(event) => { setInstagram(event.target.value) }} />
                    </div>
                    <div>
                        <p>LinkedIn:</p>
                        <input type="text" value={linkedin} onChange={(event) => { setLinkedIn(event.target.value) }} />
                    </div>
                </fieldset>
            </div>
            <div className='text-center'>
                <button onClick={addBusiness} className="btn btn-primary">Add</button>
            </div>
        </div>
        <h2 className="text-center mt-5">Businesses</h2>
        <div className="businesses">
            {businesses.map((business, key) => {
                let t2b = business;
                let abt = business.about;

                return <T2Business business={t2b} about={abt} key={business._id} />
            })}
        </div>
        <ToastContainer />
    </div >;
}

export default Businesses;