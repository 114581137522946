import React, { useState } from 'react'
import Axios from './../config/axios.config'

function User({ user }) {

    let [update, setAction] = useState(false);
    let [muser, setUser] = useState(user);
    const [fname, setFname] = useState(user.firstname);
    const [lname, setLname] = useState(user.lastname);
    const [phone, setPhone] = useState(user.phone);
    const [email, setEmail] = useState(user.email);

    const updateUser = () => {
        Axios.post("/user/update/" + user._id, {
            firstname: fname.length > 0 ? fname : user.firstname,
            lastname: lname.length > 0 ? lname : user.lastname,
            phone: phone.length > 0 ? phone : user.phone,
            email: email.length > 0 ? email : user.email
        }).then(response => {
            console.log(response.data);
            if (response.data.success) {
                setUser(response.data.data);
                setAction(false);
            } else {

            }
            // setUsers(users.push(response))
        })
    };

    const toggleUpdate = () => {
        setAction(!update);
    }

    return <div className="user">
        {
            update ?
                <div className="text-dark p-3">
                    <label>First Name</label><input type="text" placeholder={muser.firstname} onChange={(event) => { setFname(event.target.value) }} /><br />
                    <label>Last Name</label><input type="text" placeholder={muser.lastname} onChange={(event) => { setLname(event.target.value) }} /><br />
                    <label>Phone</label><input type="text" placeholder={muser.phone} onChange={(event) => { setPhone(event.target.value) }} /><br />
                    <label>Email</label><input type="text" placeholder={muser.email} onChange={(event) => { setEmail(event.target.value) }} /><br />
                    <button onClick={toggleUpdate} className="btn btn-danger">Cancel</button>
                    <button onClick={updateUser} className="btn btn-success">Save</button>
                </div>
                :
                <div className="text-dark p-3">
                    <table>
                        <tbody>
                            <tr><th>First Name:</th><td>{muser.firstname}</td></tr>
                            <tr><th>Last Name:</th><td>{muser.lastname}</td></tr>
                            <tr><th>Phone:</th><td>{muser.phone}</td></tr>
                            <tr><th>Email:</th><td>{muser.email}</td></tr>
                            <button onClick={toggleUpdate} className="btn btn-primary">Update</button>
                        </tbody>
                    </table>
                </div>

        }

    </div>
}

export default User;