import User from "./User";
import React, { useState, useEffect } from 'react'
import Axios from './../config/axios.config'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Users() {

    const [users, setUsers] = useState([])

    useEffect(() => {
        Axios.get("/user")
            .then((response) => {
                // console.log(response);
                setUsers(response.data);
            });
    }, []);



    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");

    const addUser = () => {

        if (fname === "" || lname === "" || phone === "" || email === "") {
            toast.error("Fill in all fields!");
            return;
        }

        Axios.post("/user", {
            firstname: fname,
            lastname: lname,
            phone: phone,
            email: email
        }).then(response => {
            console.log(response.data);
            if (response.data.success) {
                setUsers([...users, response.data.data]);
                setFname(""); setLname("");
                setPhone(""); setEmail("");
                toast.success("User Added")
            } else {

            }
        })


    };


    return <div className="container">
        <div className='form bg-light p-3'>
            <h2>Add User</h2>
            <div className="form-fields">
                <div>
                    <p>First name:</p>
                    <input type="text" value={fname} onChange={(event) => { setFname(event.target.value) }} />
                </div>
                <div>
                    <p>Last name:</p>
                    <input type="text" value={lname} onChange={(event) => { setLname(event.target.value) }} />
                </div>
                <div>
                    <p>Phone:</p>
                    <input type="text" value={phone} onChange={(event) => { setPhone(event.target.value) }} />
                </div>
                <div>
                    <p>Email:</p>
                    <input type="text" value={email} onChange={(event) => { setEmail(event.target.value) }} />
                </div>
            </div>
            <div className="text-center">
                <button onClick={addUser} className="btn btn-primary">Add</button>
            </div>
        </div>
        <h2 className="text-center mt-5">Users</h2>
        <div className="users">
            {users.map((user, key) => <User user={user} key={key} />)}
        </div>
        <ToastContainer />
    </div>;
}

export default Users;