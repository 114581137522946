import React, { useState } from 'react'
import Axios from './../config/axios.config'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Business({ business }) {

    let [update, setAction] = useState(false);
    let [mbizz, setBizz] = useState(business);
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [bannerImage, setBanner] = useState(null);
    const [tagline, setTagline] = useState("");
    const [bandImage, setBand] = useState(null);
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [about, setAbout] = useState("");
    const [mon_to_fri, setMtF] = useState("")
    const [saturday, setSaturday] = useState("")
    const [sunday, setSunday] = useState("")
    const [holidays, setHolidays] = useState("")
    const [description, setDescription] = useState("");
    const [image, setImage] = useState(null);
    const [itemName, setItemName] = useState("");
    const [facebook, setFacebook] = useState("")
    const [twitter, setTwitter] = useState("")
    const [instagram, setInstagram] = useState("")
    const [linkedin, setLinkedIn] = useState("")

    const updateBusiness = () => {
        Axios.post("/business/t1/update/" + business._id, {
            name: name.length > 0 ? name : business.name,
            type: type.length > 0 ? type : business.type,
            phone: phone.length > 0 ? phone : business.phone,
            email: email.length > 0 ? email : business.email,
            tagline: tagline.length > 0 ? tagline : business.tagline,
            address: address.length > 0 ? address : business.address,
            about: about.length > 0 ? about : business.about,
            mon_to_fri: mon_to_fri.length > 0 ? mon_to_fri : business.mon_to_fri,
            saturday: saturday.length > 0 ? saturday : business.saturday,
            sunday: sunday.length > 0 ? sunday : business.sunday,
            holidays: holidays.length > 0 ? holidays : business.holidays,
            fblink: facebook.length > 0 ? facebook : business.fblink,
            twitterlink: twitter.length > 0 ? twitter : business.twitterlink,
            iglink: instagram.length > 0 ? instagram : business.iglink,
            linkedInlink: linkedin.length > 0 ? linkedin : business.linkedInlink
        }).then(response => {
            console.log(response.data);
            if (response.data.success) {
                setBizz(response.data.data);
                setAction(false);
            } else {

            }
        })
    };

    const updateBanner = () => {
        const fd = new FormData()
        fd.append("bannerImage", bannerImage);

        Axios.post("/business/t1/update/banner/" + business._id, fd)
            .then(response => {
                console.log(response.data);
                if (response.data.success) {
                    setBizz(response.data.data);
                    setAction(false);
                } else {

                }
            })
    };

    const updateBand = () => {
        const fd = new FormData()

        fd.append("bandImage", bandImage);
        Axios.post("/business/t1/update/band/" + business._id, fd)
            .then(response => {
                console.log(response.data);
                if (response.data.success) {
                    setBizz(response.data.data);
                    setAction(false);
                } else {

                }
            })
    };

    const toggleUpdate = () => {
        setAction(!update);
    }

    const addItem = () => {

        if (description === "") {
            toast.error("Please fill in all fields");
            return;
        }

        if (image == null) {
            toast.error("Please provide an image");
            return;
        }

        const fd = new FormData();

        fd.append("itemImage", image);
        fd.append("description", description);
        fd.append("name", itemName);

        Axios.post("/business/t1/item/" + business._id, fd, {
            onUploadProgress: ProgressEvent => {
                console.log("Upload progress: " + Math.round(ProgressEvent.loaded / ProgressEvent.total * 100) + '%')
            }
        }
        ).then(response => {

            if (response.data.success) {
                console.log(response.data)
                setBizz(response.data.data);
                toast.success("New Item Added!")
                setImage(null);
                setDescription("");
                setItemName("");
            } else {
                toast.error("Unable to add Item!")
            }
        })
    };

    const deleteItem = (key) => {
        Axios.delete("/business/t1/item/" + business._id, { data: { key: key } })
            .then(response => {
                console.log(response.data);
                if (response.data.success) {
                    setBizz(response.data.data);
                    setAction(false);
                } else {

                }
            })
    }

    const prevUrl = "https://store.bunika.co.ke/" + business.link;

    return <div className="business">

        {
            update ?
                <div>
                    <div className="business-info text-dark p-3">
                        <div className="form-fields">
                            <div>
                                <p>Business name:</p>
                                <input type="text" defaultValue={mbizz.name} onChange={(event) => { setName(event.target.value) }} />
                            </div>
                            <div>
                                <p>Type:</p>
                                <select onChange={(event) => { setType(event.target.value) }} >
                                    <option>Products</option>
                                    <option>Services</option>
                                </select>
                            </div>
                            <div>
                                <p>Tag Line:</p>
                                <input type="text" defaultValue={mbizz.tagline} onChange={(event) => { setTagline(event.target.value) }} />
                            </div>
                            <div>
                                <p>Phone:</p>
                                <input type="text" defaultValue={mbizz.phone} onChange={(event) => { setPhone(event.target.value) }} />
                            </div>
                            <div>
                                <p>Email:</p>
                                <input type="text" defaultValue={mbizz.email} onChange={(event) => { setEmail(event.target.value) }} />
                            </div>
                            <div>
                                <p>Address:</p>
                                <input type="text" defaultValue={mbizz.address} onChange={(event) => { setAddress(event.target.value) }} />
                            </div>
                            <div className="abt-text">
                                <p>About:</p>
                                <textarea type="text" onChange={(event) => { setAbout(event.target.value) }}>{mbizz.about}</textarea>
                            </div>
                            <div>
                                <p>Monday to Friday:</p>
                                <input type="text" defaultValue={mbizz.mon_to_fri} onChange={(event) => { setMtF(event.target.value) }} />
                            </div>
                            <div>
                                <p>Saturday:</p>
                                <input type="text" defaultValue={mbizz.saturday} onChange={(event) => { setSaturday(event.target.value) }} />
                            </div>
                            <div>
                                <p>Sunday:</p>
                                <input type="text" defaultValue={mbizz.sunday} onChange={(event) => { setSunday(event.target.value) }} />
                            </div>
                            <div>
                                <p>Holidays:</p>
                                <input type="text" defaultValue={mbizz.holidays} onChange={(event) => { setHolidays(event.target.value) }} />
                            </div>
                            <div>
                                <p>Facebook:</p>
                                <input type="text" defaultValue={mbizz.fblink} onChange={(event) => { setFacebook(event.target.value) }} />
                            </div>
                            <div>
                                <p>Twitter:</p>
                                <input type="text" defaultValue={mbizz.twitterlink} onChange={(event) => { setTwitter(event.target.value) }} />
                            </div>
                            <div>
                                <p>Instagram:</p>
                                <input type="text" defaultValue={mbizz.iglink} onChange={(event) => { setInstagram(event.target.value) }} />
                            </div>
                            <div>
                                <p>LinkedIn:</p>
                                <input type="text" defaultValue={mbizz.linkedInlink} onChange={(event) => { setLinkedIn(event.target.value) }} />
                            </div>

                        </div>

                        <button onClick={updateBusiness} className="btn btn-success">Save</button>
                        <div className="images">
                            <div className="">
                                <p>Banner Image:</p>
                                <input type="file" onChange={(event) => { setBanner(event.target.files[0]) }} />
                                <button onClick={updateBanner} className="btn btn-success">Save Image</button>
                            </div>
                            <div className="">
                                <p>Band Image:</p>
                                <input type="file" onChange={(event) => { setBand(event.target.files[0]) }} />
                                <button onClick={updateBand} className="btn btn-success">Save Image</button>
                            </div>
                        </div>
                        <button onClick={toggleUpdate} className="btn btn-danger">Cancel</button>
                    </div>
                    <div className="admin-items">
                        <h5>Items</h5>
                        <div className='itms'>
                            {mbizz.items.map((item, key) =>
                                <div className="item" key={item.name + "-" + key}>
                                    <img src={item.itemImage} alt="Item" />
                                    <h5>{item.name}</h5>
                                    <p>{item.description}</p>
                                    <button className="btn btn-danger" onClick={() => deleteItem(key)}>Delete</button>
                                </div>
                            )}
                            <div className="add-item">
                                <div>
                                    <p>Image</p>
                                    <input type="file" onChange={(event) => { setImage(event.target.files[0]) }} />
                                </div>
                                <div>
                                    <p>Name</p>
                                    <input value={itemName} type="text" onChange={(event) => { setItemName(event.target.value) }} />
                                </div>
                                <div>
                                    <p>Description</p>
                                    <textarea value={description} type="text" onChange={(event) => { setDescription(event.target.value) }}>
                                    </textarea>
                                </div>
                                <div className="text-center">
                                    <button onClick={addItem} className="btn btn-primary mt-3">Add</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                :
                <div className="text-dark p-3">
                    <table>
                        <tbody>
                            <tr><td></td><td><a target="_blank" href={prevUrl} rel="noopener noreferrer"><button className="me-auto btn btn-primary">View</button></a></td></tr>
                            <tr><td>Name:</td><td>{mbizz.name}</td></tr>
                            <tr><td>Type:</td><td>{mbizz.type}</td></tr>
                            <tr><td>Phone:</td><td>{mbizz.phone}</td></tr>
                            <tr><td>Email:</td><td>{mbizz.email}</td></tr>
                            <tr><td>Tag Line:</td><td>{mbizz.tagline}</td></tr>
                            <tr><td>About:</td><td>{mbizz.about}</td></tr>
                            <tr><td>Address:</td><td>{mbizz.address}</td></tr>
                            <tr><td>Monday to Friday:</td><td>{mbizz.mon_to_fri}</td></tr>
                            <tr><td>Saturday:</td><td>{mbizz.saturday}</td></tr>
                            <tr><td>Sunday:</td><td>{mbizz.sunday}</td></tr>
                            <tr><td>Holidays:</td><td>{mbizz.holidays}</td></tr>
                            <tr><td>Facebook: </td><td>{mbizz.fblink}</td></tr>
                            <tr><td>Twitter: </td><td>{mbizz.twitterlink}</td></tr>
                            <tr><td>Instagram: </td><td>{mbizz.iglink}</td></tr>
                            <tr><td>LinkedIn: </td><td>{mbizz.linkedInlink}</td></tr>
                            <tr><td colSpan="2">
                                <div className="admin-items">
                                    <h5>Items</h5>
                                    <div className='itms'>
                                        {mbizz.items.map((item, key) =>
                                            <div className="item" key={item.name + "-" + key}>
                                                <img src={item.itemImage} alt="Item" />
                                                <h5>{item.name}</h5>
                                                <p>{item.description}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </td></tr>
                            <tr><td><button onClick={toggleUpdate} className="btn btn-primary">Update</button></td></tr>
                        </tbody>
                    </table>
                </div>
        }
        <ToastContainer />
    </div>
}

export default Business;